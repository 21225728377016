<template>
  <div class="user_member-box">
    <titleHeader :title="$route.meta.title"></titleHeader> 
   
    
    <div class="user-header-box">
      <div class="user-header-content">
        <p>会员宫令显示</p>
        <p>当前宫令：100</p>
        <p>下一级会员：700</p>
      </div>
    </div>
    <div class="user-header-box">
      <div class="user-header-content">
        <p>闫总发的会员体系表的内容</p>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  components: {
    
  },
  data() {
    return {
      currentRate: 1,
      total: 4,
      showShare:false,
      showNav:true,
      navShow:true,
      wrapScroll: {},
      options : [
        { name: '微信', icon: 'wechat' },
        { name: '微博', icon: 'weibo' },
        { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'poster' },
        { name: '二维码', icon: 'qrcode' },
      ],
    }
    
  },
  
  created(){
    
    
  },
  mounted() {
   
    
  },
  methods: {
    onSelect(){
      this.showShare = false;
    },
    onClickLeft(){
      history.back();
    },
    onClickRight(){
      // this.$toast("点击图标");
      this.showShare = true;
    },
    goDetail(){
      this.$router.push("/detail");
    },
    
  },
  computed:{
    
  }
};
</script>

<style scoped lang="scss">
.user_member-box{
  background: #f7f7f7;
  min-height: 100vh;
  .user-header-box{
    padding-top: 80px;
    margin-bottom: 20px;
    height: 300px;
    background: #fcf;
    .user-header-content{
      text-align: center;
      margin:0px auto;
      .van-progress{
        margin:0px auto;
        width: 50%;
      }
      p{
        margin-top: 30px;
      }
    }
  }
  
  
}
</style>
